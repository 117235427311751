import React, { useState, useEffect } from "react";
import { SortableTree } from "./TreeView";
import initialData from "./data.json";
import { TreeItems } from "./types";

function App() {
	const dataTyped: TreeItems = initialData;
	const [data, setData] = useState(dataTyped);

	useEffect(() => {
		const savedData = localStorage.getItem("autoSavedData");
		if (savedData) {
			setData(JSON.parse(savedData) as TreeItems);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("autoSavedData", JSON.stringify(data));
	}, [data]);

	const handleSave = () => {
		const json = JSON.stringify(data, null, 2); // Convert data to JSON string with formatting
		const blob = new Blob([json], { type: "application/json" });
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "data.json"; // Set the file name
		a.click();
		URL.revokeObjectURL(url); // Clean up
	};

	const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e: ProgressEvent<FileReader>) => {
				const result = e.target?.result;
				if (typeof result === "string") {
					const json = JSON.parse(result);
					setData(json);
				}
			};
			reader.readAsText(file);
		}
	};

	const [newItemName, setNewItemName] = useState("");

	const handleAddItem = () => {
		if (newItemName.trim() !== "") {
			const newItem = {
				id: Date.now().toString(),
				order: 0,
				name: newItemName,
				acl: {},
				parentId: "root",
				children: [],
			};
			setData([...data, newItem]);
			setNewItemName("");
		}
	};

	return (
		<div style={{ padding: "20px" }}>
			<h1>Edit Meta Structure</h1>
			<input
				type="text"
				value={newItemName}
				onChange={(e) => setNewItemName(e.target.value)}
				placeholder="Enter new item name"
				style={{
					marginTop: "20px",
					padding: "10px",
					fontSize: "16px",
					border: "1px solid #ccc",
					marginBottom: "10px",
				}}
			/>
			<button
				onClick={handleAddItem}
				style={{
					marginTop: "10px",
					padding: "10px",
					fontSize: "16px",
					border: "none",
					color: "#fff",
					backgroundColor: "#28a745",
					marginBottom: "20px",
				}}
			>
				Add Item
			</button>
			<button
				onClick={handleSave}
				style={{
					marginTop: "20px",
					padding: "10px",
					fontSize: "16px",
					border: "none",
					color: "#fff",
					backgroundColor: "#007bff",
					marginBottom: "20px",
				}}
			>
				Save JSON
			</button>
			<input
				type="file"
				accept="application/json"
				onChange={handleUpload}
				style={{
					marginTop: "20px",
					padding: "10px",
					fontSize: "16px",
					border: "none",
					color: "#fff",
					marginBottom: "20px",
				}}
			/>
			<SortableTree
				collapsible
				indicator
				removable
				items={data}
				setItems={setData}
			/>
		</div>
	);
}

export default App;
