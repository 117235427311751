// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_Button__jRcom {
  padding: 14px 20px;
  border: none;
  background-color: #242836;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #f6f8ff;
  cursor: pointer;
  outline: none;
  transform: scale(1);
  transition: transform 0.2s, background 0.4s;

  &:hover,
  &:focus {
    background-color: #2f3545;
    transform: scale(1.02);
  }

  &:focus {
    box-shadow: 0 0 0 4px #4c9ffe;
  }

  &:active {
    transform: scale(0.95);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Button.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,2CAA2C;;EAE3C;;IAEE,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".Button {\n  padding: 14px 20px;\n  border: none;\n  background-color: #242836;\n  border-radius: 8px;\n  font-size: 14px;\n  font-weight: 600;\n  color: #f6f8ff;\n  cursor: pointer;\n  outline: none;\n  transform: scale(1);\n  transition: transform 0.2s, background 0.4s;\n\n  &:hover,\n  &:focus {\n    background-color: #2f3545;\n    transform: scale(1.02);\n  }\n\n  &:focus {\n    box-shadow: 0 0 0 4px #4c9ffe;\n  }\n\n  &:active {\n    transform: scale(0.95);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `Button_Button__jRcom`
};
export default ___CSS_LOADER_EXPORT___;
