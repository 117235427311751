// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmModal_ConfirmModal__IQPk8 {
  --width: 250px;
  --height: 120px;

  display: flex;
  flex-direction: column;
  width: var(--width);
  height: var(--height);
  position: fixed;
  top: calc(100vh / 2 - var(--height) / 2);
  left: calc(100vw / 2 - var(--width) / 2);
  border-radius: 10px;
  box-shadow: -1px 0 15px 0 rgba(34, 33, 81, 0.01),
    0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  z-index: 1;

  h1 {
    flex: 1 1;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }

  button {
    width: 50px;
    height: 23px;
    background: #242836;
    color: #f6f8ff;
    border: none;
    border-radius: 3px;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      background-color: #2f3545;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ConfirmModal/ConfirmModal.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;;EAEf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;EACf,wCAAwC;EACxC,wCAAwC;EACxC,mBAAmB;EACnB;0CACwC;EACxC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;;EAEV;IACE,SAAO;IACP,eAAe;IACf,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,eAAe;;IAEf;MACE,yBAAyB;IAC3B;EACF;AACF","sourcesContent":[".ConfirmModal {\n  --width: 250px;\n  --height: 120px;\n\n  display: flex;\n  flex-direction: column;\n  width: var(--width);\n  height: var(--height);\n  position: fixed;\n  top: calc(100vh / 2 - var(--height) / 2);\n  left: calc(100vw / 2 - var(--width) / 2);\n  border-radius: 10px;\n  box-shadow: -1px 0 15px 0 rgba(34, 33, 81, 0.01),\n    0px 15px 15px 0 rgba(34, 33, 81, 0.25);\n  padding: 15px;\n  box-sizing: border-box;\n  background-color: white;\n  text-align: center;\n  z-index: 1;\n\n  h1 {\n    flex: 1;\n    font-size: 16px;\n    font-weight: normal;\n    line-height: 20px;\n  }\n\n  button {\n    width: 50px;\n    height: 23px;\n    background: #242836;\n    color: #f6f8ff;\n    border: none;\n    border-radius: 3px;\n    margin: 0 5px;\n    cursor: pointer;\n\n    &:hover {\n      background-color: #2f3545;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmModal": `ConfirmModal_ConfirmModal__IQPk8`
};
export default ___CSS_LOADER_EXPORT___;
