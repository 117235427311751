// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper_Wrapper__60rII {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  justify-content: flex-start;

  &.Wrapper_center__Lp-jq {
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Wrapper/Wrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,2BAA2B;;EAE3B;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".Wrapper {\n  display: flex;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 20px;\n  justify-content: flex-start;\n\n  &.center {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": `Wrapper_Wrapper__60rII`,
	"center": `Wrapper_center__Lp-jq`
};
export default ___CSS_LOADER_EXPORT___;
