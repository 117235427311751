// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List_List__\\+1P7- {
  display: grid;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  min-width: 350px;
  grid-gap: 10px;
  padding: 20px;
  padding-bottom: 0;
  margin: 10px;
  border-radius: 5px;
  min-height: 200px;
  transition: background-color 350ms ease;
  grid-template-columns: repeat(var(--columns, 1), 1fr);

  &:after {
    content: '';
    height: 10px;
    grid-column-start: span var(--columns, 1);
  }

  &.List_horizontal__Ap7x\\+ {
    width: 100%;
    grid-auto-flow: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/List/List.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,uCAAuC;EACvC,qDAAqD;;EAErD;IACE,WAAW;IACX,YAAY;IACZ,yCAAyC;EAC3C;;EAEA;IACE,WAAW;IACX,sBAAsB;EACxB;AACF","sourcesContent":[".List {\n  display: grid;\n  grid-auto-rows: max-content;\n  box-sizing: border-box;\n  min-width: 350px;\n  grid-gap: 10px;\n  padding: 20px;\n  padding-bottom: 0;\n  margin: 10px;\n  border-radius: 5px;\n  min-height: 200px;\n  transition: background-color 350ms ease;\n  grid-template-columns: repeat(var(--columns, 1), 1fr);\n\n  &:after {\n    content: '';\n    height: 10px;\n    grid-column-start: span var(--columns, 1);\n  }\n\n  &.horizontal {\n    width: 100%;\n    grid-auto-flow: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"List": `List_List__+1P7-`,
	"horizontal": `List_horizontal__Ap7x+`
};
export default ___CSS_LOADER_EXPORT___;
